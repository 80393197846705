/**
 * The `ReviewSelections` component renders a table with prescription details and additional
 * information, allowing the user to review their selections and potentially upgrade their lenses.
 * @returns The ReviewSelections component is returning a JSX element.
 */
import React from 'react';
import * as styles from 'assets/styles';
import { useNavigate } from 'react-router-dom';
import { UserPrescriptionReviewTable } from 'components/commonComponents';
import { usePrescriptionContext, useSnackbar } from 'context';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'helper/Auth';
import { ApiPostUserPrescription } from 'helper/API';
import { message, routesConfig } from 'helper/config';
import { addItemToCart } from 'redux/actions';
import { STORAGE_KEYS } from 'helper/APP';
import { IoClose } from 'react-icons/io5';
import { sessionStorageHandler } from 'utils';

const ReviewSelections = ({ handleBackButtonClick, closeButton }) => {
  // Function to create data for each row
  function createData(eye, sph, cyl, axis, pd) {
    return { eye, sph, cyl, axis, pd };
  }
  const { showSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const products = useSelector((store) => store?.products);

  const { frameSize } = useSelector((store) => store.frameSize);

  const {
    selectedProduct: {
      product_frame: { _id: productID, price: productPrice },
    },
  } = products;
  const lensSelection = useSelector((store) => store.lensSelection);

  const { selectedLensData } = lensSelection;
  const {
    leftEyeAttributes,
    rightEyeAttributes,
    lensSelectionFlowData,
    setSelectedLensType,
    setLeftEyeAttributes,
    setRightEyeAttributes,
    setFinishLensSelection,
    setLensSelectionFlowData,
    setSelectedPrescriptionType,
    reset,
    selectedPrescriptionOption,
    prescriptionImages,
  } = usePrescriptionContext();

  const allPrices = [productPrice];
  /* The code is iterating over the values of the `lensSelectionFlowData` object and checking if each value has a valid `price` property. If the value has a valid `price` property (not NaN), it is added to the `allPrices` array using the `push()` method. */
  Object.values(lensSelectionFlowData).forEach((x) => {
    !isNaN(x?.price) && allPrices?.push(x?.price);
  });

  /* The `totalPrice` variable is calculated using the `reduce()` method on the `allPrices` array. */
  const totalPrice = allPrices?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  // Rows with the eye attributes data
  const rows = [
    createData(
      'OD',
      rightEyeAttributes.sph,
      rightEyeAttributes.cyl,
      rightEyeAttributes.axis,
      rightEyeAttributes.power
    ),
    createData(
      'OS',
      leftEyeAttributes.sph,
      leftEyeAttributes.cyl,
      leftEyeAttributes.axis,
      leftEyeAttributes.power
    ),
    createData('PD', rightEyeAttributes.pd, leftEyeAttributes.pd),
  ];

  const onContinue = async () => {
    if (Auth.isLogin()) {
      const { status } = await ApiPostUserPrescription(
        Auth.getUserId(),
        leftEyeAttributes,
        rightEyeAttributes
      );

      if (status === 200)
        showSnackbar(
          message?.PRESCRIPTION_SUBMITTED_SUCCESS,
          'soft',
          'success'
        );

      const cartRequestBody = {
        user_id: Auth.getUserId(),
        items: [
          {
            //? Frame
            product_frame_detail_id: frameSize?.frame_id,
            product_frame_id: productID,
            frame_size_id: frameSize?.sizeId,
            lenses: selectedLensData,
            quantity: 1,
            item_price: totalPrice,
            //? Prescription
            prescription_data: {
              right_eye_attributes: rightEyeAttributes,
              left_eye_attributes: leftEyeAttributes,
              //   "pd_comman":34 //? can not send pd individual
            },
          },
        ],
        final_amount: totalPrice,
      };
      dispatch(addItemToCart(cartRequestBody, navigate, showSnackbar));
      reset();
    } else {
      sessionStorageHandler.setItem(
        STORAGE_KEYS.prescriptionData,
        JSON.stringify({
          leftEyeAttributes,
          rightEyeAttributes,
        })
      );
      const cartList = JSON.parse(
        sessionStorageHandler.getItem(STORAGE_KEYS.cartList) || '[]'
      );
      cartList?.push({
        product_frame_detail_id: products?.selectedProduct?.frames?.filter(
          (frame) => frame?._id === frameSize?.frame_id
        )?.[0],
        product_frame_id: products.selectedProduct,
        frame_size_id: frameSize?.sizeId,
        lenses: selectedLensData,
        _id: productID,
        quantity: 1,
        item_price: totalPrice,
        //? Prescription
        prescription_data: {
          right_eye_attributes: rightEyeAttributes,
          left_eye_attributes: leftEyeAttributes,
          //   "pd_comman":34 //can not send pd individual
        },
      });
      sessionStorageHandler.setItem(
        STORAGE_KEYS.cartList,
        JSON.stringify(cartList)
      );
      const cartData = JSON.parse(
        sessionStorageHandler.getItem(STORAGE_KEYS.cartData) ||
          JSON.stringify({
            items: [],
            final_amount: 0,
          })
      );
      cartData.items.push({
        product_frame_detail_id: frameSize?.frame_id,
        product_frame_id: productID,
        frame_size_id: frameSize?.sizeId,
        lenses: selectedLensData,
        quantity: 1,
        item_price: totalPrice,
        //! Prescription
        prescription_data: {
          right_eye_attributes: rightEyeAttributes,
          left_eye_attributes: leftEyeAttributes,
          //   "pd_comman":34 //can not send pd individual
        },
      });
      const final = cartData?.items?.reduce((total, data) => {
        return total + (data?.item_price || 0);
      }, 0);
      cartData.final_amount = final;
      sessionStorageHandler.setItem(
        STORAGE_KEYS.cartData,
        JSON.stringify(cartData)
      );
      navigate(routesConfig.shippingCart);
      reset();
    }

    setSelectedLensType('');
    setFinishLensSelection(false);
    setLensSelectionFlowData({});
    setSelectedPrescriptionType('');
    setLeftEyeAttributes({
      axis: '',
      cyl: 0.0,
      sph: 0.0,
      pd: 63,
      power: '',
    });
    setRightEyeAttributes({
      axis: '',
      cyl: 0.0,
      sph: 0.0,
      pd: 63,
      power: '',
    });
  };

  return (
    <>
      <div>
        <div className="hidden screen1280:block">
          <div className="flex items-center gap-3 pb-[14px] mb-[14px] border-b-4">
            <button onClick={closeButton}>
              <IoClose size={20} color="#868A8B" />
            </button>
            <styles.Para16Tag>Review your selections</styles.Para16Tag>
          </div>
        </div>
        <div>
          <styles.Head32Tag
            className="pb-7 screen1280:hidden"
            color={'#2E3233'}
            $weight={600}
          >
            Review your selections
          </styles.Head32Tag>
          <styles.Para22Tag className="mb-5">
            Prescription Details
          </styles.Para22Tag>
        </div>

        {selectedPrescriptionOption === 1 ? (
          <UserPrescriptionReviewTable rows={rows} />
        ) : (
          <img
            className="mx-auto h-[20rem] object-contain"
            src={prescriptionImages}
            alt=""
          />
        )}

        <div className="flex items-center justify-between mb-11 mt-5">
          <styles.Para22Tag>Total</styles.Para22Tag>
          <styles.Head32Tag $weight={600}>${totalPrice}</styles.Head32Tag>
        </div>

        <styles.ButtonWrapper
          className="mt-7 m-auto screen1280:!hidden"
          onClick={() => onContinue()}
        >
          Confirm & Add to cart
        </styles.ButtonWrapper>
      </div>
      <div className="hidden screen1280:block">
        <div className="flex justify-between gap-4">
          <styles.ButtonWrapper
            onClick={handleBackButtonClick}
            $bg_color={'#FFF'}
            $bColor={'#DEE2E4'}
            color={'#5F6364'}
            $hover_color={'#2E3233'}
            $hover_bg={'#FFF'}
            $hover_border={'#DEE2E4'}
            width={'100%'}
            $border_color={'#DEE2E4'}
          >
            Back
          </styles.ButtonWrapper>
          <styles.ButtonWrapper onClick={() => onContinue()} width={'100%'}>
            Confirm & Add to cart
          </styles.ButtonWrapper>
        </div>
      </div>
    </>
  );
};

export default ReviewSelections;
